<template>
  <RekapPasokanMitraForm mode="Tambah" module="Rekap Pasokan Mitra"> </RekapPasokanMitraForm>
</template>

<script>
import RekapPasokanMitraForm from './form';

const RekapPasokanMitraAdd = {
  name: 'RekapPasokanMitraAdd',
  components: { RekapPasokanMitraForm },
};

export default RekapPasokanMitraAdd;
</script>
